import "./licenses.scss";
import { BrowserView, MobileView } from "react-device-detect";

export default function Licenses() {
  return (
    <>
      <BrowserView>
        <div id="licenses-web" onClick={(e) => e.stopPropagation()}>
          <div id="license-header">Licenses</div>

          <div id="license-list">
            <div className="license-header">
              This project uses icons made by <a target="_blank" href="https://fontawesome.com/">Font Awesome</a>, <a target="_blank" href="https://github.com/microsoft/vscode-codicons">VS Code Icons</a> licensed under the CC BY 4.0 License. See the <a target="_blank" href="https://creativecommons.org/licenses/by/4.0/legalcode.txt">License</a> for details.
            </div>
            <div className="license-header">
              This project uses icons made by <a target="_blank" href="http://google.github.io/material-design-icons/">Material Design Icons</a>, <a href="https://github.com/grommet/grommet-icons">Grommet Icons</a> licensed under the Apache License Version 2.0. See the <a target="_blank" href="https://www.apache.org/licenses/LICENSE-2.0">License</a> for details.
            </div>
            <div className="license-header">
              This project uses icons made by <a target="_blank" href="https://circumicons.com/">Circum Icons</a> licensed under the MPL-2.0 License. See the <a target="_blank" href="https://github.com/Klarr-Agency/Circum-icons?tab=MPL-2.0-1-ov-file#readme">License</a> for details.
            </div>
            <div className="license-header">
              This project uses icons made by <a target="_blank" href="https://github.com/grommet/grommet-icons">Grommet-Icons</a> licensed under Apache License Version 2.0. See the <a target="_blank" href="http://www.apache.org/licenses/">License</a> for details
            </div>
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div id="licenses-mobile" onClick={(e) => e.stopPropagation()}>
          <div id="license-header">Licenses</div>

          <div id="license-list">
          <div className="license-header">
              This project uses icons made by <a target="_blank" href="https://fontawesome.com/">Font Awesome</a>, <a target="_blank" href="https://github.com/microsoft/vscode-codicons">VS Code Icons</a> licensed under the CC BY 4.0 License. See the <a target="_blank" href="https://creativecommons.org/licenses/by/4.0/legalcode.txt">License</a> for details.
            </div>
            <div className="license-header">
              This project uses icons made by <a target="_blank" href="http://google.github.io/material-design-icons/">Material Design Icons</a>, <a href="https://github.com/grommet/grommet-icons">Grommet Icons</a> licensed under the Apache License Version 2.0. See the <a target="_blank" href="https://www.apache.org/licenses/LICENSE-2.0">License</a> for details.
            </div>
            <div className="license-header">
              This project uses icons made by <a target="_blank" href="https://circumicons.com/">Circum Icons</a> licensed under the MPL-2.0 License. See the <a target="_blank" href="https://github.com/Klarr-Agency/Circum-icons?tab=MPL-2.0-1-ov-file#readme">License</a> for details.
            </div>
            <div className="license-header">
              This project uses icons made by <a target="_blank" href="https://github.com/grommet/grommet-icons">Grommet-Icons</a> licensed under Apache License Version 2.0. See the <a target="_blank" href="http://www.apache.org/licenses/">License</a> for details
            </div>
          </div>
        </div>
      </MobileView>
    </>
  )
}
