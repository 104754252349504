import "./auth.scss";
import { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { login, register } from "../../apis/user"

// components
import Header from "../../components/header"
import Footer from "../../components/footer"
import Licenses from "../../components/licenses"

export default function Register() {
  const [email, setEmail] = useState(process.env.NODE_ENV === "production" ? '' : 'kmrobogram@gmail.com')
  const [password, setPassword] = useState(process.env.NODE_ENV === "production" ? '' : 'qqqqqq')
  const [confirmPassword, setConfirmpassword] = useState('')
  const [newAccount, setNewaccount] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMsg, setErrormsg] = useState('')
  const [showLicense, setShowlicense] = useState(false)

  const theLogin = () => {
    if (email && password) {
      const data = { email, password }

      setLoading(true)

      login(data)
        .then((res) => {
          if (res.ok) {
            return res.json()
          }

          throw res
        })
        .then((res) => {
          if (res) {            
            localStorage.setItem("token", res.token)

            if (res.adderId) {
              localStorage.setItem("addedBy", "true")
            }

            window.location = "/dashboard"
          }
        })
        .catch((err) => {
          if (err.status === 400) {
            err.json().then(({ status }) => {
              setLoading(false)

              if (status.includes("unsetup")) {
                localStorage.setItem("token", status.split(":")[1])

                window.location = "/setup"
              } else {
                switch (status) {
                  case "nonExist":
                    theRegister()

                    break;
                  case "unverified":
                    window.location = "/verify"

                    break;
                  default:

                }
              }
            })
          }
        })
    } else {
      setLoading(false)

      if (!email) {
        setErrormsg("E-mail is empty")
      } else {
        setErrormsg("Password is empty")
      }
    }
  }
  const theRegister = () => {
    setNewaccount(true)

    if (email && password && confirmPassword) {
      if (password === confirmPassword && password.length >= 6) {
        const data = { email, password }

        setLoading(true)

        register(data)
          .then((res) => {
            if (res.ok) {
              return res.json()
            }

            throw res
          })
          .then((res) => {
            if (res) {
              window.location = "/verify"
            }
          })
          .catch((err) => {
            if (err.status === 400) {
              err.json().then(({ status }) => {
                if (status === "accountExisted") {
                  setErrormsg("Account already exists")
                }
              })
            }
          })
      } else {
        setLoading(false)

        if (password != confirmPassword) {
          setErrormsg("Password doesn't match")
        } else {
          setErrormsg("Password needs to be at least 6 characters long")
        }
      }
    }
  }

  useEffect(() => {
    if (isMobile) {
      window.location = "/mobile"
    }
  }, [])

  return (
    <div id="register-page">
      <div id="register-web" style={{ opacity: loading ? 0.5 : 1 }}>
        <Header/>

        <form id="form" onSubmit={e => {
          if (!loading) {
            theLogin()
          }

          e.preventDefault()
        }}>
          <div id="form-header">LOG-IN/SIGN-UP</div>
          <div id="form-mini-header">DETACH organizes your work/life so you can reach them easily</div>

          <div id="inputs">
            <div className="input-container">
              <div className="input-header">E-mail:</div>
              <div className="input-value">
                <input maxLength="50" type="email" onChange={e => setEmail(e.target.value)} disabled={loading} value={email}/>
              </div>
            </div>
            <div className="input-container">
              <div className="input-header">Password:</div>
              <div className="input-value">
                <input maxLength="50" type="password" onChange={e => setPassword(e.target.value)} disabled={loading} value={password}/>
              </div>
            </div>
            
            {newAccount && (
              <div className="input-container">
                <div className="input-header">Confirm your password:</div>
                <div className="input-value">
                  <input maxLength="50" type="password" onChange={e => setConfirmpassword(e.target.value)} disabled={loading} value={confirmPassword}/>
                </div>
              </div>
            )}
          </div>

          <div id="errormsg">{errorMsg}</div>

          <div className="row"><button id={"form-submit" + (loading ? "-disabled" : "")} disabled={loading}>LOGIN/REGISTER</button></div>

          {newAccount && <div id="agree-header">By registering, you agreed to <a className="link" target="_blank" href="/terms">Terms</a></div>}

          {loading && (
            <div id="loading-box">
              <div id="loading-container" style={{ marginTop: 30 }}>
                <div id="loading-icon" style={{ height: 30, width: 30 }}>
                  <AiOutlineLoading3Quarters style={{ color: 'black', height: '100%', width: '100%' }}/>
                </div>
              </div>
            </div>
          )}
        </form>

        <div id="footer"><Footer showLicense={() => setShowlicense(true)}/></div>

        {showLicense && (
          <div id="hidden-box" onClick={() => {
            setShowlicense(false)
          }}>
            {showLicense && <Licenses/>}
          </div>
        )}
      </div>
    </div>
  )
}
