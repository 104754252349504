import "./usagerecords.scss";
import { useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

export default function Usagerecords({ usageRecords, togglePlan }) {
  return (
    <div id="usage-records" onClick={(e) => e.stopPropagation()}>
      <div id="usage-records-header">YOUR USAGE</div>

      <div className="row">
        <div id="records">
          <div className="record">
            <div className="column"><div className="record-header">Note(s): </div></div>
            <div className="record-usage">
              {usageRecords.note.use < usageRecords.note.limit ? 
                usageRecords.note.use
                :
                usageRecords.note.use
              } of <strong>{usageRecords.note.limit}</strong>
            </div>
          </div>
          <div className="record">
            <div className="column"><div className="record-header">Schedule(s): </div></div>
            <div className="record-usage">
              {usageRecords.schedule.use < usageRecords.schedule.limit ? 
                usageRecords.schedule.use
                :
                usageRecords.schedule.use
              } of <strong>{usageRecords.schedule.limit}</strong>
            </div>
          </div>
          <div className="record">
            <div className="column"><div className="record-header">Task(s): </div></div>
            <div className="record-usage">
              {usageRecords.task.use < usageRecords.task.limit ? 
                usageRecords.task.use
                :
                usageRecords.task.use
              } of <strong>{usageRecords.task.limit}</strong>
            </div>
          </div>
          <div className="record">
            <div className="column"><div className="record-header">Todolist(s): </div></div>
            <div className="record-usage">
              {usageRecords.todolist.use < usageRecords.todolist.limit ? 
                usageRecords.todolist.use
                :
                usageRecords.todolist.use
              } of <strong>{usageRecords.todolist.limit}</strong>
            </div>
          </div>
          <div className="record">
            <div className="column"><div className="record-header">Folder(s): </div></div>
            <div className="record-usage">
              {usageRecords.folder.use < usageRecords.folder.limit ? 
                usageRecords.folder.use
                :
                usageRecords.folder.use
              } of <strong>{usageRecords.folder.limit}</strong>
            </div>
          </div>
          <div className="record">
            <div className="column"><div className="record-header">Project(s): </div></div>
            <div className="record-usage">
              {usageRecords.project.use < usageRecords.project.limit ? 
                usageRecords.project.use
                :
                usageRecords.project.use
              } of <strong>{usageRecords.project.limit}</strong>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div id={"usage-records-toggleplan" + (usageRecords.loading ? "-disabled" : "")} style={{ opacity: usageRecords.loading ? 0.3 : 1 }} onClick={() => {
          if (!usageRecords.loading) {
            togglePlan()
          }
        }}>
          {usageRecords.planPaused ? 
            'RESUME PLAN'
            :
            'PAUSE PLAN'
          }
        </div>
      </div>

      {usageRecords.loading && (
        <div id="loading-box" style={{ height: 20, marginVertical: 20 }}>
          <div id="loading-container">
            <div id="loading-icon" style={{ height: 20, width: 20 }}>
              <AiOutlineLoading3Quarters style={{ color: 'black', height: '100%', width: '100%' }}/>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
