const url = process.env.REACT_APP_URL

export const login = data => {
  return fetch(url + '/api/user/login', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const register = data => {
  return fetch(url + '/api/user/register', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

// desktop or mobile
export const verify = data => {
  return fetch(url + '/api/user/verify', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const readedIntro = data => {
  return fetch(url + '/api/user/readed_intro', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const getToken = data => {
  return fetch(url + '/api/user/get_token', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const userActivated = data => {
  return fetch(url + '/api/user/user_activated', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const setup = data => {
  return fetch(url + '/api/user/setup', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const getUserInfo = data => {
  return fetch(url + '/api/user/get_user_info', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const getUsage = data => {
  return fetch(url + '/api/user/get_usage', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const isExceedUsage = data => {
  return fetch(url + '/api/user/is_exceed_usage', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const checkIfSubscribed = data => {
  return fetch(url + '/api/user/check_if_subscribed', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const getUsageRecords = data => {
  return fetch(url + '/api/user/get_usage_records', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const reportProblem = data => {
  return fetch(url + '/api/user/report_problem', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const getReports = data => {
  return fetch(url + '/api/user/get_reports', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const getProgress = data => {
  return fetch(url + '/api/user/get_progress', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const getNotes = data => {
  return fetch(url + '/api/user/get_notes', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const getNotifications = data => {
  return fetch(url + '/api/user/get_notifications', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const like = data => {
  return fetch(url + '/api/user/like', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const dislike = data => {
  return fetch(url + '/api/user/dislike', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const createCheckout = data => {
  return fetch(url + '/api/user/create_checkout', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const doneSubscribe = data => {
  return fetch(url + '/api/user/done_subscribe', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const updatePlan = data => {
  return fetch(url + '/api/user/update_plan', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const togglePlan = data => {
  return fetch(url + '/api/user/toggle_plan', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const requestFeature = data => {
  return fetch(url + '/api/user/request_feature', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}
