const url = process.env.REACT_APP_URL

export const createNote = data => {
  return fetch(url + '/api/note/create_note', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const seeNotes = data => {
  return fetch(url + '/api/note/see', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const seeDesktopNote = data => {
  return fetch(url + '/api/note/see_desktop_note', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const saveNote = data => {
  return fetch(url + '/api/note/save_note', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const removeNote = data => {
  return fetch(url + '/api/note/remove_note', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const seeAllNotes = data => {
  return fetch(url + '/api/note/see_all_notes', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const saveAllNotes = data => {
  return fetch(url + '/api/note/save_all_notes', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}
