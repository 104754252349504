import "./verify.scss";
import { useState } from "react";
import { CiMail } from "react-icons/ci";

// components
import Header from "../../components/header"
import Footer from "../../components/footer"
import Licenses from "../../components/licenses"

export default function Verify() {
  const [showLicense, setShowlicense] = useState(false)

  return (
    <div id="verify">
      <Header/>

      <div id="main-web">
        <div id="body">
          <div>
            <div id="body-header">Thank you for joining DETACH</div>

            <div id="mail-icon"><CiMail style={{ color: '#A0CBFF', height: '100%', width: '100%' }}/></div>

            <div id="mail-header">Please check your e-mail to activate your account</div>
          </div>
        </div>
        <div id="footer"><Footer showLicense={() => setShowlicense(true)}/></div>

        {showLicense && (
          <div id="hidden-box" onClick={() => {
            setShowlicense(false)
          }}>
            {showLicense && <Licenses/>}
          </div>
        )}
      </div>
    </div>
  )
}