import "./mobilecoming.scss";
import { useState } from "react";

import Header from "../../components/header"
import Footer from "../../components/footer"
import Licenses from "../../components/licenses"

export default function Mobilecoming() {
  const [showLicense, setShowlicense] = useState(false)

  return (
    <div id="mobilecoming-page">
      <Header/>

      <div id="main">
        <div id="main-header">iOS and Android app<br/>coming very soon</div>
      </div>

      <div id="footer"><Footer showLicense={() => setShowlicense(true)}/></div>

      {showLicense && (
        <div id="hidden-box" onClick={() => {
          setShowlicense(false)
        }}>
          {showLicense && <Licenses/>}
        </div>
      )}
    </div>
  )
}
