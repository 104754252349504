import "./footer.scss";
import { BrowserView, MobileView } from "react-device-detect"
import { FaFacebook, FaInstagramSquare, FaLinkedin, FaPinterest } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6"

export default function Footer({ showLicense }) {
  return (
    <>
      <BrowserView style={{ height: '100%', width: '100%' }}>
        <div id="footer-comp-web">
          <div className="row">
            <div id="socialmedias-container">
              <div className="column"><div id="footer-comp-header">FOLLOW US</div></div>

              <div id="socialmedias">
                <div className="socialmedia" onClick={() => window.open("https://www.facebook.com/joindetach", "_blank")}>
                  <img src="/socialmedia-logos/Facebook_Logo_Primary.png" style={{ width: '100%' }}/>
                </div>
                <div className="socialmedia" onClick={() => window.open("https://www.instagram.com/joindetach", "_blank")}>
                  <img src="/socialmedia-logos/Instagram_Glyph_Gradient.png" style={{ width: '100%' }}/>
                </div>
                <div className="socialmedia" onClick={() => window.open("https://www.linkedin.com/company/joindetach", "_blank")}>
                  <img src="/socialmedia-logos/LI-In-Bug.png" style={{ width: '100%' }}/>
                </div>
                <div className="socialmedia" onClick={() => window.open("https://www.pinterest.ca/joindetach", "_blank")}>
                  <img src="/socialmedia-logos/P-Badge-Red-CMYK.jpg" style={{ width: '100%' }}/>
                </div>
                <div className="socialmedia" onClick={() => window.open("https://x.com/joindetach", "_blank")}>
                   <img src="/socialmedia-logos/logo-black.png" style={{ width: '100%' }}/>
                </div>
              </div>
            </div>

            <div className="column"><div id="footer-licenses" onClick={() => showLicense()}>Licenses</div></div>
          </div>

          <div id="footer-header">© {new Date().getFullYear()} Detach by Geottuse, Inc. | All Rights Reserved</div>
        </div>
      </BrowserView>
      <MobileView style={{ height: '100%', width: '100%' }}>
        <div id="footer-comp-mobile">
          <div className="row">
            <div id="socialmedia-container">
              <div className="column"><div id="footer-comp-header">FOLLOW US</div></div>

              <div id="socialmedias">
                <div className="socialmedia" onClick={() => window.open("https://www.facebook.com/joindetach", "_blank")}>
                  <img src="/socialmedia-logos/Facebook_Logo_Primary.png" style={{ width: '100%' }}/>
                </div>
                <div className="socialmedia" onClick={() => window.open("https://www.instagram.com/joindetach", "_blank")}>
                  <img src="/socialmedia-logos/Instagram_Glyph_Gradient.png" style={{ width: '100%' }}/>
                </div>
                <div className="socialmedia" onClick={() => window.open("https://www.linkedin.com/company/joindetach", "_blank")}>
                  <img src="/socialmedia-logos/LI-In-Bug.png" style={{ width: '100%' }}/>
                </div>
                <div className="socialmedia" onClick={() => window.open("https://www.pinterest.ca/joindetach", "_blank")}>
                  <img src="/socialmedia-logos/P-Badge-Red-CMYK.jpg" style={{ width: '100%' }}/>
                </div>
                <div className="socialmedia" onClick={() => window.open("https://x.com/joindetach", "_blank")}>
                  <img src="/socialmedia-logos/logo-black.png" style={{ width: '100%' }}/>
                </div>
              </div>
            </div>
          </div>

          <div id="footer-header">© {new Date().getFullYear()} Detach by Geottuse, Inc. | All Rights Reserved</div>
          <div className="row"><div id="footer-licenses" onClick={() => showLicense()}>Licenses</div></div>
        </div>
      </MobileView>
    </>
  )
}
