import "./seereports.scss";
import { useEffect, useState } from "react";
import { getReports } from "../../apis/user";
import { BrowserView, MobileView } from "react-device-detect";

// components
import Header from "../../components/header";

export default function Seereports() {
  const [offset, setOffset] = useState(0)
  const [datas, setDatas] = useState([])
  const [latestId, setLatestid] = useState(0)

  const getTheReports = () => {
    const data = { offset }

    getReports(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          setOffset(res.offset)
          setDatas(res.datas)
          setLatestid(res.latestId)
        }
      })
      .catch((err) => {
        if (err.status === 400) {

        }
      })
  }

  useEffect(() => {
    getTheReports()
  }, [])

  return (
    <div id="seereports-page">
      <BrowserView>
        <div id="seereports-page-web">
          <Header
            showControls={false}
          />
          <div id="main">
            <div id="main-header">All report(s)</div>

            <div id="reports">
              {datas.map((data => (
                <div className="report" key={data.key}>
                  <div className="report-header">{data.email}</div>
                  <div className="report-info">{data.input}</div>
                </div>
              )))}
            </div>

            {(datas.length > 0 && datas.pop()["id"] < latestId) && <div id="more-button" onClick={() => getTheReports()}>See more</div>}
          </div>
        </div>
      </BrowserView>
      <MobileView>
        <div id="seereports-page-mobile">
          <Header
            showControls={false}
          />
          <div id="main">
            <div id="main-header">All report(s)</div>

            <div id="reports">
              {datas.map((data => (
                <div className="report" key={data.key}>
                  <div className="report-header">{data.email}</div>
                  <div className="report-info">{data.input}</div>
                </div>
              )))}
            </div>

            {(datas.length > 0 && datas.pop()["id"] < latestId) && <div id="more-button" onClick={() => getTheReports()}>See more</div>}
          </div>
        </div>
      </MobileView>
    </div>
  )
}
