import "./setup.scss";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { getToken, userActivated, setup } from "../../apis/user";

// components
import Header from "../../components/header"
import Footer from "../../components/footer"
import Licenses from "../../components/licenses"

export default function Setup() {
  const { id } = useParams()

  const [token, setToken] = useState('')
  const [firstName, setFirstname] = useState('')
  const [lastName, setLastname] = useState('')
  const [loading, setLoading] = useState(false)
  const [errorMsg, setErrormsg] = useState('')
  const [showLicense, setShowlicense] = useState(false)

  const getTheToken = () => {
    getToken({ genId: id })
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          setToken(res.token)
        }
      })
      .catch((err) => {
        if (err.status === 400) {
          err.json().then(({ status }) => {

          })
        }
      })
  }
  const userTheActivated = () => {
    const data = { token }

    userActivated(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          localStorage.setItem("token", token)

          window.location = "/setup"
        }
      })
      .catch((err) => {
        if (err.status === 400) {
          err.json().then(({ status }) => {
            if (status == "verified") {

            }
          })
        }
      })
  }
  const theSetup = () => {
    setLoading(true)

    if (firstName && lastName) {
      const data = { token, firstName, lastName }

      setup(data)
        .then((res) => {
          if (res.ok) {
            return res.json()
          }

          throw res
        })
        .then((res) => {
          if (res) {            
            localStorage.setItem("token", token)

            window.location = "/dashboard"
          }
        })
        .catch((err) => {
          if (err.status === 400) {
            err.json().then(({ status }) => {
              alert(status)
            })
          }
        })
    } else {
      if (!firstName) {
        setErrormsg("Please enter the first name")
      } else {
        setErrormsg("Please enter the last name")
      }
      
      setLoading(false)
    }
  }

  useEffect(() => {
    if (id) {
      getTheToken()
    } else {
      setToken(localStorage.getItem("token"))
    }
  }, [])

  useEffect(() => {
    if (token) {
      userTheActivated()
    }
  }, [token])

  return (
    <div id="setup">
      <div id="setup-web" style={{ opacity: loading ? 0.5 : 1 }}>
        <Header/>

        <div id="form">
          <div id="form-header">Setup your account</div>
          <div className="form-mini-header">You are verified</div>
          <div className="form-mini-header">Finish setting up your account then see everything on one screen</div>

          <div id="body">
            <div id="inputs">
              <div className="input-container">
                <div className="input-header">Enter your first name:</div>
                <div className="input-value">
                  <input 
                    maxLength="50" type="text" 
                    onChange={e => setFirstname(e.target.value)} 
                    disabled={loading} value={firstName} onKeyDown={(e) => {
                      e.stopPropagation()

                      if (e.keyCode === 13) {
                        theSetup()
                      }
                    }}
                  />
                </div>
              </div>
              <div className="input-container">
                <div className="input-header">Enter your last name:</div>
                <div className="input-value">
                  <input 
                    maxLength="50" type="text" 
                    onChange={e => setLastname(e.target.value)} 
                    disabled={loading} value={lastName} onKeyDown={(e) => {
                      e.stopPropagation()

                      if (e.keyCode === 13) {
                        theSetup()
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div id="errormsg">{errorMsg}</div>

          <div className="row">
            <div id={"form-submit" + (loading ? "-disabled" : "")} disabled={loading} onClick={() => {
              if (!loading) {
                theSetup()
              }
            }}>Finish</div>
          </div>

          {loading && (
            <div id="loading-box">
              <div id="loading-container" style={{ marginTop: 30 }}>
                <div id="loading-icon" style={{ height: 30, width: 30 }}>
                  <AiOutlineLoading3Quarters style={{ color: 'black', height: '100%', width: '100%' }}/>
                </div>
              </div>
            </div>
          )}
        </div>

        <div id="footer"><Footer showLicense={() => setShowlicense(true)}/></div>

        {showLicense && (
          <div id="hidden-box" onClick={() => {
            setShowlicense(false)
          }}>
            {showLicense && <Licenses/>}
          </div>
        )}
      </div>
    </div>
  )
}
