import "./pricing.scss";
import { useState, useEffect } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { isExceedUsage } from "../../apis/user"

import Header from "../../components/header"
import Priceplans from "../../components/priceplans"
import Footer from "../../components/footer"
import Licenses from "../../components/licenses"

export default function Pricing() {
  const [token, setToken] = useState('')
  const [isActive, setIsactive] = useState(false)
  const [showLicense, setShowlicense] = useState(false)

  const isTheExceedUsage = () => {
    const data = { token, dataType: "" }

    isExceedUsage(data)
      .then((res) => {
        if (res.ok) {
          return res.json()
        }

        throw res
      })
      .then((res) => {
        if (res) {
          const { msg } = res
        
          setIsactive(msg.split(":")[1] == "true")
        }
      })
  }

  useEffect(() => {
    setToken(localStorage.getItem("token"))
  }, [])

  useEffect(() => {
    if (token) isTheExceedUsage()
  }, [token])

  return (
    <>
      <BrowserView>
        <div id="pricing-web-page">
          <Header/>

          <div id="main">
            <div id="main-header">
              Use Detach to your fullest with all the features you need in this plan
              <br/>
              <div style={{ fontSize: 30 }}>(more plans are coming....)</div>
            </div>

            <Priceplans/>
          </div>

          <div id="footer"><Footer showLicense={() => setShowlicense(true)}/></div>

          {showLicense && (
            <div id="hidden-box" onClick={() => {
              setShowlicense(false)
            }}>
              {showLicense && <Licenses/>}
            </div>
          )}
        </div>
      </BrowserView>
      <MobileView>
        <div id="pricing-mobile-page">
          <Header/>

          <div id="main">
            <div id="main-header">
              Use Detach to your fullest with all the features you need in this plan
              <br/>
              <div style={{ fontSize: 30 }}>(more plans are coming....)</div>
            </div>

            <Priceplans/>
          </div>

          <div id="footer"><Footer showLicense={() => setShowlicense(true)}/></div>

          {showLicense && (
            <div id="hidden-box" onClick={() => {
              setShowlicense(false)
            }}>
              {showLicense && <Licenses/>}
            </div>
          )}
        </div>
      </MobileView>
    </>
    
  )
}
