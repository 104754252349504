import "./mission.scss";
import { useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";

// components
import Header from "../../components/header"
import Footer from "../../components/footer"
import Licenses from "../../components/licenses"

const header = "Why Us"
const mission = "Our mission"

const Whyus = () => {
  return (
    <>
      Thanks for visiting <strong>Detach</strong>. I know what you must be thinking. "Oh gosh, another must-be confusing management tool".
      NOPE. Hell-no!. I'm a passioniate software developer who have been using many management software tool for years and have a love and hate relationship with them.
      <br/><br/><br/><br/>
      What separates <strong>Detach</strong> from other management tools is the idea of the terms "TOGETHER" and "EASY VIEW". I want to build Detach to enable you
      to see everything together on one screen instead of clicking around and getting lost with headaches and fustrations.
      <br/><br/><br/><br/>
      I am extremely passioniate about simplicity and flexible and I want to shape <strong>Detach</strong> to make it fit that way and that's the
      main goal I am moving towards
      <br/><br/><br/><br/>
      I have been using different management tools and learning for years but what's more important to me is the opinions and feedbacks I get from customers.
      I believe users are the complete solution to this goal as I keep developing <strong>Detach</strong>. So let's work together to build this solution into a world wide management tool
    </>
  )
}

const Mission = () => {
  return (
    <>
      <strong>Detach</strong> to allow users to create schedules, tasks, notes, todolists, projects, teams, companies, and employees and see/manage everything all on one screen.
      <br/><br/>
      Unlike other project management tools, <strong>Detach</strong> enables you to manage your work with only a few clicks.
    </>
  )
}

export default function About() {
  const [showLicense, setShowlicense] = useState(false)

  return (
    <div id="mission-page">
      <BrowserView>        
        <div id="mission-web">
          <Header/>

          <div className="header">{header}</div>

          <div className="infos">
            <div className="info">
              <Whyus/>
            </div>
          </div>

          <div className="header">{mission}</div>

          <div className="infos" style={{ marginBottom: 150 }}>
            <div className="info">
              <Mission/>
            </div>
          </div>

          <div id="footer"><Footer showLicense={() => setShowlicense(true)}/></div>

          {showLicense && (
            <div id="hidden-box" onClick={() => {
              setShowlicense(false)
            }}>
              {showLicense && <Licenses/>}
            </div>
          )}
        </div>
      </BrowserView>
      <MobileView>
        <div id="mission-mobile">
          <Header/>

          <div className="header">{header}</div>

          <div className="infos">
            <div className="info">
              <Whyus/>
            </div>
          </div>

          <div className="header">{mission}</div>

          <div className="infos">
            <div className="info">
              <Mission/>
            </div>
          </div>

          <div id="footer"><Footer showLicense={() => setShowlicense(true)}/></div>

          {showLicense && (
            <div id="hidden-box" onClick={() => {
              setShowlicense(false)
            }}>
              {showLicense && <Licenses/>}
            </div>
          )}
        </div>
      </MobileView>
    </div>
  )
}
