const url = process.env.REACT_APP_URL

export const getSidebarChoices = data => {
  return fetch(url + '/api/choice/get_sidebar_choices', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const addChoice = data => {
  return fetch(url + '/api/choice/add_choice', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const moveInBetween = data => {
  return fetch(url + '/api/choice/move_in_between', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const moveChoice = data => {
  return fetch(url + '/api/choice/move_choice', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

// desktop
export const seeChoiceItems = data => {
  return fetch(url + '/api/choice/see_choice_items', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const checkValidBetween = data => {
  return fetch(url + '/api/choice/check_valid_between', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const checkDeletable = data => {
  return fetch(url + '/api/choice/check_deletable', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const deleteChoice = data => {
  return fetch(url + '/api/choice/delete_choice', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const postponeTask = data => {
  return fetch(url + '/api/choice/postpone_task', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const completedTask = data => {
  return fetch(url + '/api/choice/completed_task', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const removeParent = data => {
  return fetch(url + '/api/choice/remove_parent', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}

export const searchProjects = data => {
  return fetch(url + '/api/choice/search_projects', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
}
