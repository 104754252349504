import "./landing.scss";
import { useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { CiClock1, CiViewList } from "react-icons/ci";
import { GrUserWorker } from "react-icons/gr";
import { IoIosPeople } from "react-icons/io";
import { MdEventNote, MdOutlineTask } from "react-icons/md";
import { AiFillDislike, AiFillLike, AiFillProject } from "react-icons/ai";
import { like, dislike } from "../../apis/user"

// components
import Header from "../../components/header";
import Footer from "../../components/footer";
import Licenses from "../../components/licenses";

const mastheadMessage = "and MANAGE THEM ALL ON ONE SCREEN without a single headache or frustration"
const message = "DETACH enables you to really relax by keeping your work/life organized so that you can easily reach them without multiple searches"

export default function Landing() {
  const [dislikeBox, setDislikebox] = useState({ show: false, input: '', sent: false })
  const [likeBox, setLikebox] = useState({ show: false, input: '', sent: false })
  const [showLicense, setShowlicense] = useState(false)

  const theLike = () => {
    if (!likeBox.show) {
      const data = { type: 'num' }

      like(data)
        .then((res) => {
          if (res.ok) {
            return res.json()
          }

          throw res
        })
        .then((res) => {
          if (res) {
            const info = document.getElementById("landing-page")

            info.scrollTop = 0
            info.style.overflow = "hidden"

            setLikebox({ ...likeBox, show: true, input: '' })
          }
        })
    } else {
      const data = { type: 'input', input: likeBox.input }

      like(data)
        .then((res) => {
          if (res.ok) {
            return res.json()
          }

          throw res
        })
        .then((res) => {
          if (res) {
            const info = document.getElementById("landing-page")

            info.scrollTop = 0
            info.style.overflow = "auto"

            setLikebox({ ...likeBox, sent: true })

            setTimeout(function () {
              setLikebox({ ...likeBox, show: false, sent: false })
              window.scrollTo(0, 0)
            }, 2000)
          }
        })
        .catch((err) => {
          if (err.status === 400) {

          }
        })
    }
  }
  const theDislike = () => {
    if (!dislikeBox.show) {
      const info = document.getElementById("landing-page")

      info.scrollTop = 0
      info.style.overflow = "hidden"

      setDislikebox({ ...dislikeBox, show: true, input: '' })
    } else {
      const { input } = dislikeBox

      if (input) {
        const data = { input }

        dislike(data)
          .then((res) => {
            if (res.ok) {
              return res.json()
            }

            throw res
          })
          .then((res) => {
            if (res) {
              const info = document.getElementById("landing-page")

              info.scrollTop = 0
              info.style.overflow = "auto"

              setDislikebox({ ...dislikeBox, input: '', errorMsg: '', sent: true })

              setTimeout(function () {
                setDislikebox({ ...dislikeBox, show: false, sent: false })
                window.scrollTo(0, 0)
              }, 2000)
            }
          })
          .catch((err) => {
            if (err.status === 400) {

            }
          })
      } else {
        setDislikebox({ ...dislikeBox, errorMsg: "Please enter your opinion" })
      }
    }
  }
  
  return (
    <div id="landing-page">
      <BrowserView>
        <div id="landing-page-web">
          <Header/>

          <div id="first-masthead">
            <div id="first-masthead-headers">
              <div id="first-masthead-miniheaders">
                <h1 className="masthead-miniheader">Create your</h1>
                <div id="list-items">
                  <div className="list-item">
                    <div className="list-item-icon">
                      <MdEventNote style={{ color: 'black', height: '100%', width: '100%' }}/>
                    </div>
                    <div className="column"><div className="list-item-header">NOTES</div></div>
                  </div>
                  <div className="list-item">
                    <div className="list-item-icon">
                      <CiClock1 style={{ color: 'black', height: '100%', width: '100%' }}/>
                    </div>
                    <div className="column"><div className="list-item-header">SCHEDULES</div></div>
                  </div>
                  <div className="list-item">
                    <div className="list-item-icon">
                      <MdOutlineTask style={{ color: 'black', height: '100%', width: '100%' }}/>
                    </div>
                    <div className="column"><div className="list-item-header">TASKS</div></div>
                  </div>
                  <div className="list-item">
                    <div className="list-item-icon">
                      <AiFillProject style={{ color: 'black', height: '100%', width: '100%' }}/>
                    </div>
                    <div className="column"><div className="list-item-header">PROJECTS</div></div>
                  </div>
                </div>
                <h1 className="masthead-miniheader">{mastheadMessage}</h1>
              </div>
            </div>
            <div id="first-masthead-image" className="column">
              <div><img alt="masthead" src="/masthead.jpg"/></div>
            </div>
          </div>
          <div id="second-masthead">
            <div id="second-masthead-headers">
              <h2 id="second-masthead-header">{message}</h2>
            </div>
            <div id="second-masthead-image" className="column">
              <div>
                <img alt="beach" src="/beach.jpg"/>
              </div>
            </div>
          </div>

          <div id="footer"><Footer showLicense={() => setShowlicense(true)}/></div>

          {(dislikeBox.show || likeBox.show || showLicense) && (
            <div id="hidden" onClick={() => {
              setDislikebox({ ...dislikeBox, show: false, input: '', sent: false })
              setLikebox({ ...likeBox, show: false, input: '', sent: false })
              setShowlicense(false)
            }}>
              {dislikeBox.show && (
                <div id="dislike-box" onClick={(e) => e.stopPropagation()}>
                  <div id="dislike-header">Please tell us your opinion (Anonymously)</div>

                  <div id="dislike-input" style={{ opacity: !dislikeBox.sent ? 1 : 0.3 }}>
                    <textarea 
                      placeholder="Any words will be very appreciated" 
                      value={dislikeBox.input}
                      onChange={e => {
                        if (!dislikeBox.sent) {
                          setDislikebox({ ...dislikeBox, input: e.target.value })
                        }
                      }}
                    />
                  </div>

                  <div id="dislike-actions" style={{ opacity: !dislikeBox.sent ? 1 : 0.3 }}>
                    <div className="dislike-action" onClick={(e) => {
                      if (!dislikeBox.sent) {
                        const info = document.getElementById("landing-page")

                        info.scrollTop = 0
                        info.style.overflow = "auto"

                        setDislikebox({ ...dislikeBox, show: false, input: '', errorMsg: '' })
                      }

                      e.stopPropagation()
                    }}>Cancel</div>
                    <div className="dislike-action" onClick={(e) => {
                      if (!dislikeBox.sent) {
                        theDislike()
                      }

                      e.stopPropagation()
                    }}>Submit</div>
                  </div>

                  {dislikeBox.sent && <div id="dislike-sent-header">Thank you so much for your contribution:)</div>}
                </div>
              )}

              {likeBox.show && (
                <div id="like-box" onClick={(e) => e.stopPropagation()}>
                  <div id="like-header">Any suggestion you want to add (Anonymously)</div>

                  <div id="like-input" style={{ opacity: !likeBox.sent ? 1 : 0.3 }}>
                    <textarea
                      placeholder="Any words will be very appreciated (Optional)"
                      value={likeBox.input}
                      onChange={e => {
                        if (!likeBox.sent) {
                          setLikebox({ ...likeBox, input: e.target.value })
                        }
                      }}
                    />
                  </div>

                  <div id="like-actions" style={{ opacity: !likeBox.sent ? 1 : 0.3 }}>
                    <div className="like-action" onClick={(e) => {
                      if (!likeBox.sent) {
                        const info = document.getElementById("landing-page")

                        info.scrollTop = 0
                        info.style.overflow = "auto"

                        setLikebox({ ...likeBox, show: false, input: '' })
                      }

                      e.stopPropagation()
                    }}>Cancel</div>
                    <div className="like-action" onClick={(e) => {
                      if (!likeBox.sent) {
                        theLike()
                      }

                      e.stopPropagation()
                    }}>Submit</div>
                  </div>

                  {likeBox.sent && <div id="dislike-sent-header">Thank you so much for your contribution:)</div>}
                </div>
              )}

              {showLicense && <Licenses />}
            </div>
          )}
        </div>
      </BrowserView>
      <MobileView>
        <div id="landing-page-mobile">
          <Header/>

          <div id="first-masthead">
            <div id="first-masthead-headers">
              <div id="first-masthead-miniheader">
                <h1 className="first-masthead-miniheader">Create your</h1>

                <div id="list-items">
                  <div className="row">
                    <div className="list-item">
                      <div className="list-item-icon">
                        <MdEventNote style={{ color: 'black', height: '100%', width: '100%' }}/>
                      </div>
                      <div className="column"><div className="list-item-header">NOTES</div></div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="list-item">
                      <div className="list-item-icon">
                        <CiClock1 style={{ color: 'black', height: '100%', width: '100%' }}/>
                      </div>
                      <div className="column"><div className="list-item-header">SCHEDULES</div></div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="list-item">
                      <div className="list-item-icon">
                        <MdOutlineTask style={{ color: 'black', height: '100%', width: '100%' }}/>
                      </div>
                      <div className="column"><div className="list-item-header">TASKS</div></div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="list-item">
                      <div className="list-item-icon">
                        <AiFillProject style={{ color: 'black', height: '100%', width: '100%' }}/>
                      </div>
                      <div className="column"><div className="list-item-header">PROJECTS</div></div>
                    </div>
                  </div>                  
                </div>

                <h1 className="first-masthead-miniheader">{mastheadMessage}</h1>
              </div>
            </div>
            <div id="first-masthead-image" className="column">
              <div>
                <img alt="masthead" src="/masthead.jpg"/>
              </div>
            </div>
          </div>
          <div id="second-masthead">
            <div id="second-masthead-headers">
              <div id="second-masthead-header">{message}</div>
            </div>
            <div id="second-masthead-image" className="column">
              <img alt="beach" src="/beach.jpg"/>
            </div>
          </div>

          <div id="footer"><Footer showLicense={() => setShowlicense(true)}/></div>

          {(dislikeBox.show || likeBox.show || showLicense) && (
            <div id="hidden" onClick={() => {
              setDislikebox({ ...dislikeBox, show: false, input: '', sent: false })
              setLikebox({ ...likeBox, show: false, input: '', sent: false })
              setShowlicense(false)
            }}>
              {dislikeBox.show && (
                <div id="dislike-box" onClick={(e) => e.stopPropagation()}>
                  <div id="dislike-header">Please tell us your opinion (Anonymously)</div>

                  <div id="dislike-input" style={{ opacity: !dislikeBox.sent ? 1 : 0.3 }}>
                    <textarea 
                      placeholder="Any words will be very appreciated" 
                      value={dislikeBox.input}
                      onChange={e => {
                        if (!dislikeBox.sent) {
                          setDislikebox({ ...dislikeBox, input: e.target.value })
                        }
                      }}
                    />
                  </div>

                  <div id="dislike-actions" style={{ opacity: !dislikeBox.sent ? 1 : 0.3 }}>
                    <div className="dislike-action" onClick={(e) => {
                      if (!dislikeBox.sent) {
                        const info = document.getElementById("landing-page")

                        info.scrollTop = 0
                        info.style.overflow = "auto"

                        setDislikebox({ ...dislikeBox, show: false, input: '', errorMsg: '' })
                      }

                      e.stopPropagation()
                    }}>Cancel</div>
                    <div className="dislike-action" onClick={(e) => {
                      if (!dislikeBox.sent) {
                        theDislike()
                      }

                      e.stopPropagation()
                    }}>Submit</div>
                  </div>

                  {dislikeBox.sent && <div id="dislike-sent-header">Thank you so much for your contribution:)</div>}
                </div>
              )}

              {likeBox.show && (
                <div id="like-box" onClick={(e) => e.stopPropagation()}>
                  <div id="like-header">Any suggestion you want to add (Anonymously)</div>

                  <div id="like-input" style={{ opacity: !likeBox.sent ? 1 : 0.3 }}>
                    <textarea
                      placeholder="Any words will be very appreciated (Optional)"
                      value={likeBox.input}
                      onChange={e => {
                        if (!likeBox.sent) {
                          setLikebox({ ...likeBox, input: e.target.value })
                        }
                      }}
                    />
                  </div>

                  <div id="like-actions" style={{ opacity: !likeBox.sent ? 1 : 0.3 }}>
                    <div className="like-action" onClick={(e) => {
                      if (!likeBox.sent) {
                        const info = document.getElementById("landing-page")

                        info.scrollTop = 0
                        info.style.overflow = "auto"

                        setLikebox({ ...likeBox, show: false, input: '' })
                      }

                      e.stopPropagation()
                    }}>Cancel</div>
                    <div className="like-action" onClick={(e) => {
                      if (!likeBox.sent) {
                        theLike()
                      }

                      e.stopPropagation()
                    }}>Submit</div>
                  </div>

                  {likeBox.sent && <div id="dislike-sent-header">Thank you so much for your contribution:)</div>}
                </div>
              )}

              {showLicense && <Licenses />}
            </div>
          )}
        </div>
      </MobileView>
    </div>
  )
}
